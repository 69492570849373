import React, { useState } from "react"
// import { Link } from "gatsby"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormSuccessMessage from "../../components/form-success-message/form-success-message"

import "../../styles/services.scss"

const HoroscopeWriting = () => {
  const [submissionStatus, setSubmissionStatus] = useState("")
  const handleSubmissionStatus = axiosStatus => {
    setSubmissionStatus(axiosStatus)
  }

  return (
    <Layout>
      <SEO
        title="Horoscope writing"
        description="Get your Horoscope written and read by our experts."
        keyword="Horoscope writing, astrology, online"
      />
      <div className="main-sub-wrapper">
        <div className="header-wrap">
          <h1 className="header__title h1_decorative">Horoscope writing</h1>
          {/* <div className="tg-list-item">
            <input
              className="tgl tgl-flip"
              id="language-checkbox"
              type="checkbox"
            />
            <label
              className="tgl-btn"
              data-tg-off="മലയാളം"
              data-tg-on="English"
              htmlFor="language-checkbox"
            ></label>
          </div> */}
          <div className="text-left">
            <p>Why do we initiate horoscope writing?</p>
            <p>
              The life's plan written based on the alignment of the planets is
              what it is. Indian Astrology is the base for the horoscope. Based
              on the karma of the previous birth, the life experienced
              thereafter can be known by horoscope for sure.
            </p>
            <p>
              "Jananee janmasoukhyanam vardhanee kulasambadam,
              <br />
              padaveepoorvvapunyanam likhyathe janmapathrika"
              <br />
              with the above as beginning, the birth time with right "Panjanga"
              associations, "Grahanila", "Navamshakam", "Grahasphudas",
              "Panjangaphalas", "Yogaphalas", "Bhavaphala
              vicharam","Lagnabhalam", "Ayurbhavam"- knowing the accident timing
              period etc. is acknowledged and written.
            </p>
            <p>
              Also, there are rest like "Vidyabhava", "Vivahakala" the education
              and marriage related respectively which is also known and should
              happen at the time as ordained. For one who has the "Yoga" for
              education, "Poorvajanmapunarjanmavastha, dhanabhagyabhavam,
              sahodarabhavam, mathrupithrubhavam, dhashakaala niroopanam etc are
              discussed. And then "kalachakradhasha", "Nirvanadhasha",
              "Nirvanadhasha", "Adhanadhasha", "Ulpannadhasha", "Mahadhasha",
              "Mrithyudhasha" etc would be judged and "Nakshatradhasha" timing
              would be written accurately. The result of the "Dhashapaharas"
              will be written briefly and "Ashtavarga" will be calculatingly
              added.
            </p>
            <p>
              "Yadyanmyadya likhitham jathakasya shubhashubham sathyam
              <br />
              bhavathu thathsarvam gurunam karuna balal"
              <br />
              as the ending, horoscope writing is concluded.
            </p>
          </div>
          {/* <div className="text-left malayalam">
            <p>എന്തിനാണ് ജാതകം എഴുതുന്നത്?</p>
            <p>
              ജനനസമയത്തെ ഗ്രഹസ്ഥിതിയെ ആശ്രയിച്ച് എഴുതുന്ന ജീവിത ഫലകമാണ് ജാതകം.
              ഭാരതീയ ജ്യോതിഷമാണ് ജാതകത്തിനാധാരം. മുജ്ജന്മകർമ്മം അനുസരിച്ച് ജനനം
              മുതൽ മരണം വരെ അനുഭവിക്കുന്ന ഫലങ്ങൾ മുഴുവനും ജാതകം കൊണ്ട് അറിയാൻ
              കഴിയുമെന്നത് തീർച്ചയാണ്.
            </p>
            <p>
              'ജനനീ ജന്മസൗഖ്യാനാം വർ‌ദ്ധനീ കുലസമ്പദാം,
              <br />
              പദവീപൂർ‌വ്വപുണ്യാനാം ലിഖ്യതേ ജന്മപത്രികാ'
            </p>
            <p>
              എന്ന പ്രസ്താവനയോടെ തുടങ്ങുന്ന ജാതകത്തിൽ പഞ്ചാംഗയുക്തമായ ജന്മസമയം,
              ഗ്രഹനില, നവാംശകം, ഗ്രഹസ്ഫുടങ്ങൾ, പഞ്ചാംഗഫലം, യോഗഫലം എന്നിവയും
              തുടർന്ന് ഭാവഫലവിചാരം, ലഗ്നഭലം, ആയുർഭാവം - അപകടം വരാൻ സാദ്ധ്യത ഉള്ള
              കാലം കണ്ട് അറിഞ്ഞ് പരിഹാരം ചെയ്യുക, വിദ്യാഭാവം - വിദ്യാ തടസ്സങ്ങൾ
              ഉള്ള കാലത്ത് അത് അനുസരിച്ച് ഉള്ള വഴിപാട് ചെയ്യേണ്ടതാണ്. വിവാഹകാലം-
              പ്രായപൂർത്തിയാകുന്ന സമയം മുതല് വിവാഹം, സന്താനം മുതലായവ സംഭവിക്കാൻ
              ഉള്ള കാലത്ത് അത് സംഭവിക്കണം - വിദ്യാഭ്യസം പൂർത്തികരണം അതിന് ഒരു
              തടസ്സമാകില്ല. വിദ്യാഭ്യാസത്തിന് യോഗമുളള ഒരാൾക്ക്,
              പൂർ‌വജന്മപുനർ‌ജന്മാവസ്ഥ, ധനഭാഗ്യഭാവം, സഹോദരഭാവം, മാതൃപിതൃഭാവം,
              ദശകാലനിരൂപണം എന്നീകാര്യങ്ങൾ ചർച്ച ചെയ്യുന്നു, തുടർന്ന് കാലചക്രദശ,
              നിർ‌വാണദശ, ആധാനദശ, ഉല്പന്നദശ, മഹാദശ, മൃത്യുദശ എന്നിവ വിചാരണ
              ചെയ്‌തിട്ട് നക്ഷത്രദശാകാലം സൂക്ഷ്മപ്പെടുത്തി എഴുതുന്നു.
              ദശാപഹാരങ്ങളുടെ ഫലം പ്രമാണസഹിതം വിവരിച്ച് അഷ്ടവർഗം ഗണിച്ച്
              ചേർക്കുന്നു.
            </p>
            <p>
              'യദ്യന്മയാദ്യ ലിഖിതം ജാതകസ്യ ശുഭാശുഭം സത്യം
              <br />
              ഭവതു തത്സർ‌വം ഗുരൂണാം കരുണാ ബലാൽ'
              <br />
              എന്ന സമാപ്തവാക്യത്തോടെ ജാതകരചന ഉപസംഹരിക്കുന്നു.
            </p>
          </div> */}
        </div>
        <div className="service-inner-wrap">
          <h2 className="h4">Process and Fees</h2>
          <p>
            Once you submit the form and make the payment, our astrologers get
            to the calculations and will give you the result over the contact
            address you mention in the email. If you have questions after
            reading the result, then you can always communicate with our
            astrologers over email, phone, Skype etc.
          </p>
          <p>
            <b>Fees:</b> ₹5000 (INR) or $100 (USD)
          </p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              applicantRelationship: "",
              personName: "",
              personGender: "",
              bday: "",
              personTob: "",
              personPob: "",
              personStar: "",
              message: "",
            }}
            validate={values => {
              const errors = {}
              if (!values.name) {
                errors.name = "Please enter you name"
              }
              if (!values.email) {
                errors.email =
                  "Please enter you e-mail or phone number so that we could contact you"
              } else if (
                !(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.email
                  ) || /^(\+\d{1,3}[- ]?)?\d{10}$/i.test(values.email)
                )
              ) {
                errors.email = "Invalid email or number"
              }
              if (!values.applicantRelationship) {
                errors.applicantRelationship =
                  "Please enter you relationship with the person"
              }
              if (!values.personName) {
                errors.personName = "Please enter person's name"
              }
              if (!values.personGender) {
                errors.personGender = "Please enter person's gender"
              }
              if (!values.bday) {
                errors.bday = "Please enter person's date of birth"
              }
              if (!values.personTob) {
                errors.personTob = "Please enter person's time of birth"
              }
              if (!values.personPob) {
                errors.personPob = "Please enter person's place of birth"
              }
              if (!values.personStar) {
                errors.personStar = "Please enter person's star"
              }
              if (values.policy === true) {
                errors.policy = "Please check to proceed"
              }
              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false)
              axios
                .post("https://submit-form.com/uNdEE2Iv", values)
                .then(res => {
                  handleSubmissionStatus("success")
                })
                .catch(err => {
                  handleSubmissionStatus("failure")
                  console.log(err)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form className="form">
                {submissionStatus === "failure" ? (
                  <p className="form__main-error">
                    Sorry, please send again, the message wasn't sent.
                  </p>
                ) : (
                  ""
                )}

                {submissionStatus === "success" ? (
                  <FormSuccessMessage
                    fees={5000}
                    feesUSD={100}
                    stripeLinkINR="https://buy.stripe.com/eVabJic5abpv9HO9AC"
                  ></FormSuccessMessage>
                ) : (
                  <>
                    <h6 className="form__section-title">
                      Details of you - the applicant
                    </h6>
                    <div className="form__group">
                      <label htmlFor="name" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="name"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="name"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="email" className="form__label">
                        Contact Info*
                      </label>
                      <Field
                        type="text"
                        name="email"
                        id="email"
                        autocomplete="email"
                        className="form__input"
                        aria-required="true"
                        placeholder="e-mail or phone number"
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label
                        htmlFor="applicantRelationship"
                        className="form__label"
                      >
                        Relationship with the person*
                      </label>
                      <Field
                        type="text"
                        name="applicantRelationship"
                        id="applicantRelationship"
                        className="form__input"
                        placeholder="I am his/her"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="applicantRelationship"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <h6 className="form__section-title">
                      Details of the person
                    </h6>
                    <div className="form__group">
                      <label htmlFor="personName" className="form__label">
                        Name*
                      </label>
                      <Field
                        type="text"
                        name="personName"
                        id="personName"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personName"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personGender" className="form__label">
                        Gender*
                      </label>
                      <Field
                        as="select"
                        name="personGender"
                        id="personGender"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Field>
                      <ErrorMessage
                        name="personGender"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="bday" className="form__label">
                        Date of birth*
                      </label>
                      <Field
                        type="date"
                        max={
                          new Date(
                            new Date().getTime() -
                            new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .split("T")[0]
                        }
                        name="bday"
                        id="bday"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="bday"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personTob" className="form__label">
                        Time of birth*
                      </label>
                      <Field
                        type="time"
                        name="personTob"
                        id="personTob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personTob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personPob" className="form__label">
                        Place of birth* (nearest city)
                      </label>
                      <Field
                        type="text"
                        name="personPob"
                        id="personPob"
                        className="form__input"
                        aria-required="true"
                      />
                      <ErrorMessage
                        name="personPob"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="personStar" className="form__label">
                        Nakshatra/Birthstar*
                      </label>
                      <Field
                        as="select"
                        name="personStar"
                        id="personStar"
                        className="form__input"
                        aria-required="true"
                      >
                        <option value="">Select</option>
                        <option value="unknown">
                          Unknown (its fine, we will calculate it.)
                        </option>
                        <option value="aswathi">Aswathi/Ashwini</option>
                        <option value="bharani">Bharani</option>
                        <option value="karthika">Karthika/Krittika</option>
                        <option value="rohini">Rohini</option>
                        <option value="makeeram">Makeeram/Mrigashirsa</option>
                        <option value="thiruvathira">Thiruvathira/Ardra</option>
                        <option value="punartham">
                          Punartham/Punarvasu/Punarpoosam
                        </option>
                        <option value="pooyam">Pooyam/Pushya</option>
                        <option value="aayilyam">Aayilyam/Ashlesha</option>
                        <option value="makam">Makam/Magha</option>
                        <option value="pooram">Pooram/Purva Phalguni</option>
                        <option value="uthram">Uthram/Uttara Phalguni</option>
                        <option value="atham">Atham/Hasta</option>
                        <option value="chithira">Chithira/Chitra</option>
                        <option value="chothi">Chothi/Swati</option>
                        <option value="vishaakam">Vishaakham/Vishakha</option>
                        <option value="anizham">
                          Anizham/Anuradha/Anusham
                        </option>
                        <option value="thriketta">
                          Thriketta/Jyeshtha/Kettai
                        </option>
                        <option value="moolam">Moolam/Mula</option>
                        <option value="pooraadam">Pooraadam/Purvashada</option>
                        <option value="uthraadam">Uthraadam/Uthrashada</option>
                        <option value="thiruvonam">Thiruvonam/Shravana</option>
                        <option value="avittam">Avittam/Dhanishtha</option>
                        <option value="chathayam">Chathayam/Satabisha</option>
                        <option value="pooruruttaathi">
                          Pooruruttaathi/Poorva bhadrapada
                        </option>
                        <option value="uthrattathi">
                          Uthrattathi/Uttara bhadrapada
                        </option>
                        <option value="revathi">Revathi</option>
                      </Field>
                      <ErrorMessage
                        name="personStar"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="form__group">
                      <label htmlFor="message" className="form__label">
                        Message
                      </label>
                      <Field
                        as="textarea"
                        rows="4"
                        name="message"
                        id="message"
                        className="form__input"
                      />
                      <ErrorMessage
                        name="message"
                        component="p"
                        className="form__unit-error"
                      />
                    </div>
                    <div className="hidden">
                      <label>Do you align with our policy</label>
                      <Field name="policy" type="checkbox"></Field>
                    </div>
                    <div className="button-wrap">
                      <button
                        className="button"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Apply
                      </button>
                      <button className="link_text" type="reset">
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  )
}

export default HoroscopeWriting
